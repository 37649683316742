import React, { useEffect, useState } from "react";
import { useAuth } from "../../AuthContext";
import { useTranslation } from "react-i18next";
import altcha from 'altcha';

const AltchaComponent = ({ challengeUrl, onVerified }) => {

  const { token, refreshToken } = useAuth();
  const [isVerified, setIsVerified] = useState(false);
  const { t } = useTranslation();

  const customStrings = {
    "label": t("altcha.label"),
    "error": t("altcha.error"),
    "expired": t("altcha.expired"),
    "verified": t("altcha.verified"),
    "verifying": t("altcha.verifying"),
    "waitAlert": t("altcha.waitAlert"),
  };

  const stringsJson = JSON.stringify(customStrings);
  

  useEffect(() => {
    
    // Define the custom fetch function
    const altchaCustomFetch = async (url, options) => {
      try {
        let currentToken = token;

        // Aggiorna il token se necessario utilizzando refreshToken
        if (!currentToken) {
          currentToken = await refreshToken();
        }
        const headers = {
          ...options.headers,
          Authorization: `Bearer ${currentToken}`,
        };

        const response = await fetch(url, {
          ...options,
          headers,
        });

        if (!response.ok) {
          console.error(`Fetch failed with status: ${response.status}`);
          throw new Error(`Fetch failed with status: ${response.status}`);
        }

        return response;
      } catch (error) {
        console.error("Custom fetch encountered an error:", error);
        throw error;
      }
    };

    // Expose the custom fetch function globally
    window.altchaCustomFetch = altchaCustomFetch;
  }, [token, refreshToken]);

  useEffect(() => {
    const container = document.getElementById("altcha-widget-container");

    if (!container) {
      console.error("Container for Altcha widget not found.");
      return;
    }

    // Create and configure the Altcha widget
    const altchaWidget = document.createElement("altcha-widget");
    altchaWidget.setAttribute("challengeurl", challengeUrl);
    altchaWidget.setAttribute("customfetch", "altchaCustomFetch");
    altchaWidget.setAttribute("strings", stringsJson);

    const handleVerified = (event) => {
      setIsVerified(true);
      if (onVerified) onVerified(event.detail);
    };

    altchaWidget.addEventListener("verified", handleVerified);

    container.appendChild(altchaWidget);

    return () => {
      altchaWidget.removeEventListener("verified", handleVerified);
      container.innerHTML = ""; // Cleanup widget on unmount
    };
  }, [challengeUrl, onVerified, stringsJson]);

  return <div id="altcha-widget-container"></div>;

};

export default AltchaComponent;
