import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { timeslotsApi } from "../../misc/TimeslotsApi";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../AuthContext";
import Select from "react-select";

function ModalDescNote({ show, handleClose, item, onUpdate, isTimeslot, isReadOnly, startDate}) {

  const { token, updateToken } = useAuth();
  const [description, setDescription] = useState(item.description || "");
  const [note, setNote] = useState(item.note || "");
  const { t } = useTranslation();
  const maxCharacters = 2000;
  const [outputs, setOutputs] = useState([]);
  const [selectedOutput, setSelectedOutput] = useState(null);
  const rootStyles = getComputedStyle(document.documentElement);
  const mainColor = rootStyles.getPropertyValue('--main-color').trim();

  useEffect(() => {
    if (item) {
      setDescription(item.description || "");
      setNote(item.note || "");
      if (item.outputPk) {
        setSelectedOutput({ value: item.outputPk, label: `${item.output.internalCode} - ${item.output.name}` });
      } else {
        setSelectedOutput(null);
      }
    }
  }, [item]);

  const handleSaveAll = async () => {
    const updatedItem = {
      ...item,
      description,
      note,
      ...(isTimeslot
        ? {
            outputPk: selectedOutput ? selectedOutput.value : null,
        }
        : {}),
    };
    try {
      await updateToken();
      if (token) {
        if (isTimeslot) {
          await timeslotsApi.updateTimeslot(item.id, updatedItem, token);
        } else {
          await timeslotsApi.updateLeave(item.id, updatedItem, token);
        }
        onUpdate(updatedItem);
        if (description !== item.description && note !== item.note && (isTimeslot && updatedItem.outputPk !== item.outputPk)) {
          toast.success(t("descriptionSaved"));
          toast.success(t("notesSaved"));
          toast.success(t("outputSaved"));
        } else if (description !== item.description) {
          toast.success(t("descriptionSaved"));
        } else if (note !== item.note) {
          toast.success(t("notesSaved"));
        }
        handleClose();
      }
    } catch (error) {
      toast.error(
        isTimeslot 
          ? t("notesOrDescriptionOrOutputError") 
          : t("notesOrDescriptionError")
      );
      console.error("Error while saving notes, description, or output:", error);
    }
  };

  const handleResetDescription = async () => {
    setDescription("");
    const updatedItem = {
      ...item,
      description: "",
      note
    };

    try {
      await updateToken(); 
      if (token) {
        if (isTimeslot) {
          await timeslotsApi.updateTimeslot(item.id, updatedItem, token);
        } else {
          await timeslotsApi.updateLeave(item.id, updatedItem, token);
        }
        onUpdate(updatedItem);
        toast.success(t('descriptionSaved'));
      }
    } catch (error) {
      toast.error(t('descriptionError'));
      console.error("Error while saving description:", error);
    }
  };

  const handleResetNote = async () => {
    setNote("");
    const updatedItem = {
      ...item,
      note: "",
      description
    };

    try {
      await updateToken(); 
      if (token) {
        if (isTimeslot) {
          await timeslotsApi.updateTimeslot(item.id, updatedItem, token);
        } else {
          await timeslotsApi.updateLeave(item.id, updatedItem, token);
        }
        onUpdate(updatedItem);
        toast.success(t('notesSaved'));
      }
    } catch (error) {
      toast.error(t('notesError'));
      console.error("Error while saving notes:", error);
    }
  };

  useEffect(() => {
    const fetchOutputs = async () => {
      if (token && item) {
        await updateToken();
        timeslotsApi
          .getOutputsByWeek(startDate, item.projectPk, item.taskPk, token)
          .then((response) => {
            const outputData = response.data || [];
            if (outputData.length > 0) {
              const outputs = outputData.map((output) => ({
                value: output.id,
                label: `${output.internalCode} - ${output.name}`,
              }));
              setOutputs(outputs);
              if (outputs.length === 1) {
                setSelectedOutput(outputs[0]);
              }
            } else {
              setOutputs([]);
            }
          })
          .catch((error) => {
            console.error("Error while loading outputs:", error);
            setOutputs([]);
          });
      } else {
        setOutputs([]);
      }
    };
    fetchOutputs();
  }, [token, updateToken, startDate, item]);

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title className="green-color">{t('descriptionAndNotes')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formDescription">
            <Form.Label className="green-color">{t('description')}</Form.Label>
            <Form.Control
              as="textarea"
              rows={5}
              maxLength={maxCharacters}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="w-100 custom-form-control"
              style={{ fontSize: "1.1rem" }}
              placeholder={
                isReadOnly
                  ? t('noDescriptionPlaceholder') 
                  : isTimeslot
                  ? t('timeslotDescriptionPlaceholder') 
                  : t('leaveDescriptionPlaceholder')
              }
              readOnly={isReadOnly}
            />
            <div className="d-flex justify-content-between mt-2">
              <small>
                {description.length}/{maxCharacters}
              </small>
              <div>
                <Button className="green-text-button fw-semibold" onClick={handleResetDescription} disabled={isReadOnly}>
                  {t('reset')}
                </Button>
              </div>
            </div>
          </Form.Group>
          <Form.Group controlId="formNote" className="mt-4">
            <Form.Label className="green-color">{t('notes')}</Form.Label>
            <Form.Control
              as="textarea"
              rows={5}
              maxLength={maxCharacters}
              value={note}
              onChange={(e) => setNote(e.target.value)}
              className="w-100 custom-form-control"
              style={{ fontSize: "1.1rem" }}
              placeholder={
                isReadOnly
                  ? t('noNotesPlaceholder') 
                  : isTimeslot
                  ? t('timeslotNotesPlaceholder') 
                  : t('leaveNotesPlaceholder') 
              }
              readOnly={isReadOnly}
            />
            <div className="d-flex justify-content-between mt-2">
              <small>
                {note.length}/{maxCharacters}
              </small>
              <div>
                <Button className="green-text-button fw-semibold" onClick={handleResetNote} disabled={isReadOnly}>
                  {t('reset')}
                </Button>
              </div>
            </div>
          </Form.Group>
          {isTimeslot && (
            <Form.Group controlId="formLeaveType">
              <Form.Label className="green-color">{t('output')}</Form.Label>
                <Select
                  classNamePrefix="custom-select"
                  value={selectedOutput}
                  onChange={setSelectedOutput}
                  options={outputs}
                  placeholder={isReadOnly ? t('noSelectedOutputPlaceholder') : t('outputPlaceholder')}
                  noOptionsMessage={() => t('outputsNoOptions')}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: mainColor,
                      primary: mainColor,
                      neutral20: '#dee2e6',
                    },
                  })}
                  isDisabled={isReadOnly}
                />
            </Form.Group>
          )}
          <div className="d-flex justify-content-center mt-4">
            <Button
              className="green-background text-white fw-semibold wide-button"
              onClick={handleSaveAll}
              disabled={isReadOnly}
            >
              {t('save')}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default ModalDescNote;
