import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Button, Table, Form, InputGroup, Accordion, Tooltip, OverlayTrigger } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faMagnifyingGlass,
  faSort,
} from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../AuthContext";
import { timeslotsApi } from "../misc/TimeslotsApi";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { enUS, it } from "date-fns/locale";
import { useNavigate } from "react-router-dom";

function TimesheetApproval() {
  const { i18n, t } = useTranslation();
  const { token, updateToken } = useAuth();
  const [timesheetWorkflows, setTimesheetWorkflows] = useState([]);
  const [timesheetStatuses, setTimesheetStatuses] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [expandedRows, setExpandedRows] = useState([]);
  const [orderByStatus, setOrderByStatus] = useState(false);
  const [orderByDate, setOrderByDate] = useState("asc");
  const [orderByUser, setOrderByUser] = useState("asc");
  const [isSelectAll, setIsSelectAll] = useState(true);
  const [selectedWorkflows, setSelectedWorkflows] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [returnedStatusInternalCodes, setReturnedStatusInternalCodes] = useState({});

  const navigate = useNavigate();

  const toggleRow = (resourceId) => {
    setExpandedRows((prev) =>
      prev.includes(resourceId)
        ? prev.filter((id) => id !== resourceId)
        : [...prev, resourceId]
    );
  };

  const toggleSelectAll = () => {
    const filteredWorkflowIds = sortedGroupedWorkflows.flatMap(
      ({ workflows }) => workflows.map((workflow) => workflow.id)
    );
    if (isSelectAll) {
      setSelectedWorkflows((prev) => [
        ...new Set([...prev, ...filteredWorkflowIds]),
      ]);
    } else {
      setSelectedWorkflows((prev) =>
        prev.filter((id) => !filteredWorkflowIds.includes(id))
      );
    }
    setIsSelectAll((prev) => !prev);
  };

  const toggleSelectWorkflow = (workflowId) => {
    setSelectedWorkflows((prev) =>
      prev.includes(workflowId)
        ? prev.filter((id) => id !== workflowId)
        : [...prev, workflowId]
    );
  };

  const toggleSelectUserWorkflows = (resourceId) => {
    const userWorkflowIds =
      groupedWorkflows[resourceId]?.workflows.map((w) => w.id) || [];
    if (userWorkflowIds.every((id) => selectedWorkflows.includes(id))) {
      setSelectedWorkflows((prev) =>
        prev.filter((id) => !userWorkflowIds.includes(id))
      );
    } else {
      setSelectedWorkflows((prev) => [
        ...new Set([...prev, ...userWorkflowIds]),
      ]);
    }
  };

  const formatDate = (dateString) => {
    const options = {
      day: "2-digit",
      month: "short",
    };
    const date = new Date(dateString);
    return date
      .toLocaleDateString(i18n.language, options)
      .toUpperCase()
      .replace(".", "");
  };

  const formatDuration = (duration) => {
    if (!duration) return "00:00";

    const match = duration.match(/PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?/);
    if (!match) return "00:00";

    const hours = parseInt(match[1] || "0", 10);
    const minutes = parseInt(match[2] || "0", 10);

    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}`;
  };

  const parseDuration = (durationString) => {
    if (!durationString) return 0;
    const match = durationString.match(/PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?/);
    if (!match) return 0;
    const hours = parseInt(match[1] || "0", 10);
    const minutes = parseInt(match[2] || "0", 10);
    return hours * 60 + minutes;
  };

  const formatMinutesToDuration = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `PT${hours}H${minutes}M`;
  };

  const calculateResourceTotals = (workflows) => {
    const totalTime = workflows.reduce(
      (sum, workflow) => sum + parseDuration(workflow.totalTime),
      0
    );

    const totalLeaveTime = workflows.reduce(
      (sum, workflow) => sum + parseDuration(workflow.totalLeaveTime),
      0
    );

    return {
      totalTime: formatMinutesToDuration(totalTime),
      totalLeaveTime: formatMinutesToDuration(totalLeaveTime),
    };
  };

  const getStatusDetails = (statusInternalCode) => {
    switch (statusInternalCode) {
      case "PENDING":
        return { icon: "close", label: t("workflowStatuses.pending") };
      case "APPROVED":
        return { icon: "approve", label: t("workflowStatuses.approved") };
      case "REJECTED":
        return { icon: "reject", label: t("workflowStatuses.rejected") };
      case "DRAFT":
        return { icon: "reopen", label: t("workflowStatuses.draft") };
      default:
        return { icon: "none", label: t("workflowStatuses.unknown") };
    }
  };

  const getLocale = () => {
    switch (i18n.language) {
      case "it":
        return it;
      default:
        return enUS;
    }
  };

  const groupedWorkflows = timesheetWorkflows.reduce((acc, workflow) => {
    const resourceId = workflow.resource.id;
    if (!acc[resourceId]) {
      acc[resourceId] = {
        resource: workflow.resource,
        workflows: [],
      };
    }
    acc[resourceId].workflows.push(workflow);
    return acc;
  }, {});

  const sortedGroupedWorkflows = Object.values(groupedWorkflows)
    .filter(({ resource }) => {
      const fullName = `${resource.name} ${resource.surname}`.toLowerCase();
      return fullName.includes(searchTerm.toLowerCase());
    })
    .sort((a, b) => {
      const fullNameA = `${a.resource.name} ${a.resource.surname}`;
      const fullNameB = `${b.resource.name} ${b.resource.surname}`;
      return orderByUser === "asc"
        ? fullNameA.localeCompare(fullNameB)
        : fullNameB.localeCompare(fullNameA);
    });

  const fetchTimesheetWorkflows = useCallback(
    async (date) => {
      if (!date) return;
      const year = date.getFullYear();
      const month = date.getMonth();

      const startDate = new Date(year, month, 1);
      const endDate = new Date(year, month + 1, 0);

      const dateOptions = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      };
      const formattedStartDate = startDate
        .toLocaleDateString("it-IT", dateOptions)
        .split("/")
        .reverse()
        .join("-");
      const formattedEndDate = endDate
        .toLocaleDateString("it-IT", dateOptions)
        .split("/")
        .reverse()
        .join("-");

      try {
        await updateToken();
        const timesheetWorkflowsData =
          await timeslotsApi.getAllWorkflowsByApprover(
            formattedStartDate,
            formattedEndDate,
            token
          );
        setTimesheetWorkflows(timesheetWorkflowsData?.data || []);
      } catch (error) {
        console.error("Error while loading timesheet workflows:", error);
        setTimesheetWorkflows([]);
        if (error.response?.status === 401) {
          console.log("Token might be expired. Attempting to update token...");
          await updateToken();
          fetchTimesheetWorkflows(date);
        } else {
          console.error("Error while loading timesheet workflows:", error);
          setTimesheetWorkflows([]);
        }
      }
    },
    [token, updateToken]
  );

  const fetchTimesheetStatuses = useCallback(
    async () => {
      try {
        await updateToken();
        const timesheetStatusesData =
          await timeslotsApi.getAllTimesheetStatuses(token);
        setTimesheetStatuses(timesheetStatusesData?.data || []);
      } catch (error) {
        console.error("Error while loading timesheet statuses:", error);
        setTimesheetStatuses([]);
        if (error.response?.status === 401) {
          console.log("Token might be expired. Attempting to update token...");
          await updateToken();
          fetchTimesheetStatuses();
        } else {
          console.error("Error while loading timesheet workflows:", error);
          setTimesheetStatuses([]);
        }
      }
    },
    [token, updateToken]
  );

  useEffect(() => {
    fetchTimesheetWorkflows(selectedDate);
    fetchTimesheetStatuses();
  }, [selectedDate, fetchTimesheetWorkflows, fetchTimesheetStatuses]);

  const updateSelectedWorkflowsStatus = async (newStatusInternalCode) => {
    try {
      await updateToken();
      const timesheetStatus = timesheetStatuses.find(
        (status) => status.internalCode === newStatusInternalCode
      );
      if (!timesheetStatus) {
        console.error(`TimesheetStatus with internal code ${newStatusInternalCode} not founded.`);
        return;
      }
      const updatedStatuses = {};
      const updatePromises = selectedWorkflows.map((workflowId) => {
        const originalWorkflow = timesheetWorkflows.find(
          (workflow) => workflow.id === workflowId
        );
        if (!originalWorkflow) {
          console.error(`Workflow with id ${workflowId} not founded.`);
          return Promise.resolve();
        }
        const updatedWorkflow = {
          ...originalWorkflow,
          timesheetStatus: timesheetStatus,
        };
        updatedStatuses[workflowId] = updatedWorkflow.timesheetStatus?.internalCode;
        return timeslotsApi.updateWorkflow(workflowId, updatedWorkflow, token);
      });
      await Promise.all(updatePromises);     
      fetchTimesheetWorkflows(selectedDate);
      toast.success(t("workflowStatusesUpdatedSuccess"));
      setSelectedWorkflows([]);
      setReturnedStatusInternalCodes((prev) => ({
        ...prev,
        ...updatedStatuses,
      }));
    } catch (error) {
      toast.error(t("workflowStatusesUpdatedError"));
      console.error("Error while updating timesheet workflows:", error);
    }
  };

  const compareDurations = (time1, time2) => {
    return time1 === time2;
  };  

  const shouldShowWarningIcon = (workflow, returnedStatusInternalCodes) => {
    const returnedStatus = returnedStatusInternalCodes[workflow.id];
    let tooltipMessages = [];
  
    if (workflow.timesheetStatus?.internalCode === returnedStatus) {
      tooltipMessages.push(
        t("timesheet.cannotChangeStatus", {
          nuovoStato: t(`workflowStatuses.${returnedStatus.toLowerCase()}`),
          statoAttuale: t(`workflowStatuses.${workflow.timesheetStatus.internalCode.toLowerCase()}`)
        })
      );
      return { showWarning: true, tooltipMessage: tooltipMessages.join(" ") };
    }
  
    switch (workflow.timesheetStatus?.internalCode) {
      case "APPROVED": {
        const hoursApproved = compareDurations(workflow.totalTime, workflow.totalApprovedTime);
        const leaveApproved = compareDurations(workflow.totalLeaveTime, workflow.totalApprovedLeaveTime);
  
        if (!hoursApproved) {
          tooltipMessages.push(
            t("timesheet.partialApprovedHours", {
              approvedTime: formatDuration(workflow.totalApprovedTime),
              total: formatDuration(workflow.totalTime),
            })
          );
        }
        if (!leaveApproved) {
          tooltipMessages.push(
            t("timesheet.partialApprovedLeaves", {
              approvedLeaves: formatDuration(workflow.totalApprovedLeaveTime),
              totalLeaves: formatDuration(workflow.totalLeaveTime),
            })
          );
        }
  
        break;
      }
  
      case "REJECTED": {
        const hoursRejected = compareDurations(workflow.totalTime, workflow.totalRejectedTime);
        const leaveRejected = compareDurations(workflow.totalLeaveTime, workflow.totalRejectedLeaveTime);
  
        if (!hoursRejected) {
          tooltipMessages.push(
            t("timesheet.partialRejectedHours", {
              rejectedTime: formatDuration(workflow.totalRejectedTime),
              total: formatDuration(workflow.totalTime),
            })
          );
        }
        if (!leaveRejected) {
          tooltipMessages.push(
            t("timesheet.partialRejectedLeaves", {
              rejectedLeaves: formatDuration(workflow.totalRejectedLeaveTime),
              totalLeaves: formatDuration(workflow.totalLeaveTime),
            })
          );
        }
  
        break;
      }
  
      case "PENDING": {
        const hoursPending = compareDurations(workflow.totalTime, workflow.totalPendingTime);
        const leavePending = compareDurations(workflow.totalLeaveTime, workflow.totalPendingLeaveTime);
  
        if (!hoursPending) {
          tooltipMessages.push(
            t("timesheet.partialPendingHours", {
              pendingTime: formatDuration(workflow.totalPendingTime),
              total: formatDuration(workflow.totalTime),
            })
          );
        }
        if (!leavePending) {
          tooltipMessages.push(
            t("timesheet.partialPendingLeaves", {
              pendingLeaves: formatDuration(workflow.totalPendingLeaveTime),
              totalLeaves: formatDuration(workflow.totalLeaveTime),
            })
          );
        }
  
        break;
      }
  
      default:
        return { showWarning: false, tooltipMessage: null };
    }
  
    return {
      showWarning: tooltipMessages.length > 0,
      tooltipMessage: tooltipMessages.join(" "),
    };
  };
  
  
  return (
    <div className="row">
      <div className="col-6 text-start">
        <h4 className="fw-semibold">{t("timesheetApproval")} </h4>
      </div>
      <div className="row">
        <div className="col-12 text-start">
          <div className="d-flex gap-3">
            <Button
              className="green-text-button fw-semibold d-flex align-items-center"
              onClick={() => updateSelectedWorkflowsStatus("DRAFT")}
            >
              <img
                src={`${process.env.PUBLIC_URL}/reopen.svg`}
                alt="Reopen"
                style={{
                  width: "1rem",
                }}
                className="mr-2"
              />
              {t("reopen")}
            </Button>
            <Button
              className="green-text-button fw-semibold d-flex align-items-center"
              onClick={() => updateSelectedWorkflowsStatus("PENDING")}
            >
              <img
                src={`${process.env.PUBLIC_URL}/close.svg`}
                alt="Close"
                style={{
                  width: "1rem",
                }}
                className="mr-2"
              />
              {t("close")}
            </Button>
            <Button
              className="green-text-button fw-semibold d-flex align-items-center"
              onClick={() => updateSelectedWorkflowsStatus("APPROVED")}
            >
              <img
                src={`${process.env.PUBLIC_URL}/approve.svg`}
                alt="Approve"
                style={{
                  width: "1rem",
                }}
                className="mr-2"
              />
              {t("approve")}
            </Button>
            <Button
              className="green-text-button fw-semibold d-flex align-items-center"
              onClick={() => updateSelectedWorkflowsStatus("REJECTED")}
            >
              <img
                src={`${process.env.PUBLIC_URL}/reject.svg`}
                alt="Reject"
                style={{
                  width: "1rem",
                }}
                className="mr-2"
              />
              {t("reject")}
            </Button>           
          </div>
          <Table hover>
            <thead>
              <tr>
                <th className="px-0">
                  <div className="row">
                    <div className="col-2">
                      <InputGroup>
                        <Form.Control
                          type="text"
                          placeholder={t("search")}
                          className="custom-form-control"
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <InputGroup.Text
                          id="search"
                          style={{ backgroundColor: "transparent" }}
                        >
                          <FontAwesomeIcon
                            icon={faMagnifyingGlass}
                            className="green-color"
                          />
                        </InputGroup.Text>
                      </InputGroup>
                    </div>
                    <div className="col-2">
                      <Button
                        className="white-text-button fw-semibold mr-2 w-100"
                        onClick={() => {
                          setOrderByUser((prev) =>
                            prev === "asc"
                              ? "desc"
                              : prev === "desc"
                              ? "asc"
                              : "asc"
                          );
                          setOrderByDate(null);
                          setOrderByStatus(null);
                        }}
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/user.svg`}
                          alt="Order by users"
                          style={{
                            width: "1rem",
                          }}
                          className="mr-2 mb-1"
                        />
                        {t("orderByUsers")}
                        <FontAwesomeIcon
                          icon={faSort}
                          className="ms-2"
                          color="white"
                        />
                      </Button>
                    </div>
                    <div className="col-2">
                      <Button
                        className="white-text-button fw-semibold w-100"
                        onClick={() => {
                          setOrderByStatus((prev) =>
                            prev === "asc"
                              ? "desc"
                              : prev === "desc"
                              ? "asc"
                              : "asc"
                          );
                          setOrderByDate(null);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="mr-2"
                          color="white"
                        />
                        {t("orderByStatus")}
                        <FontAwesomeIcon
                          icon={faSort}
                          className="ms-2"
                          color="white"
                        />
                      </Button>
                    </div>
                    <div className="col-2">
                      <Button
                        className="white-text-button fw-semibold w-100"
                        onClick={() => {
                          setOrderByDate((prev) =>
                            prev === "asc"
                              ? "desc"
                              : prev === "desc"
                              ? "asc"
                              : "asc"
                          );
                          setOrderByStatus(null);
                        }}
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/calendar3.svg`}
                          alt="Order by date"
                          style={{
                            width: "1rem",
                          }}
                          className="mr-2 mb-1"
                        />
                        {t("orderByDate")}
                        <FontAwesomeIcon
                          icon={faSort}
                          className="ms-2"
                          color="white"
                        />
                      </Button>
                    </div>
                    <div className="col-2 date-picker-full-width">
                      <DatePicker
                        selected={selectedDate}
                        onChange={setSelectedDate}
                        locale={getLocale()}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        className="form-control custom-form-control text-center"
                      />
                    </div>
                    <div className="col-2">
                      <Button
                        className="white-text-button fw-semibold w-100"
                        onClick={toggleSelectAll}
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/${
                            isSelectAll ? "selectAll" : "deselectAll"
                          }.svg`}
                          alt={isSelectAll ? t("selectAll") : t("deselectAll")}
                          style={{
                            width: "1rem",
                          }}
                          className="mr-2 mb-1"
                        />
                        {t(isSelectAll ? "selectAll" : "deselectAll")}
                      </Button>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {sortedGroupedWorkflows.map(({ resource, workflows }) => (
                <React.Fragment key={resource.id}>
                  <tr
                    onClick={() => toggleRow(resource.id)}
                    style={{ cursor: "pointer" }}
                  >
                    <td className="text-start fw-semibold">
                      <div className="row">
                        <div className="col-9">
                          <h5 className="m-0">
                            <img
                              src={
                                expandedRows.includes(resource.id)
                                  ? `${process.env.PUBLIC_URL}/contract.svg`
                                  : `${process.env.PUBLIC_URL}/expand.svg`
                              }
                              alt={
                                expandedRows.includes(resource.id)
                                  ? "Collapse"
                                  : "Expand"
                              }
                              style={{
                                width: "1rem",
                              }}
                              className="mr-2 mb-1"
                            />
                            {resource.name} {resource.surname}
                            <span className="ml-3 text-muted small">
                              <strong>{t("totalTime")}:</strong>{" "}
                              {formatDuration(
                                calculateResourceTotals(workflows).totalTime
                              )}{" "}
                              |<strong> {t("totalLeaveTime")}:</strong>{" "}
                              {formatDuration(
                                calculateResourceTotals(workflows)
                                  .totalLeaveTime
                              )}
                            </span>
                          </h5>
                        </div>
                        <div className="col-3 text-end">
                          <Form.Check
                            type="checkbox"
                            className="custom-switch"
                            label={t("selectAll")}
                            reverse
                            checked={groupedWorkflows[
                              resource.id
                            ]?.workflows.every((workflow) =>
                              selectedWorkflows.includes(workflow.id)
                            )}
                            onChange={(e) => {
                              e.stopPropagation();
                              toggleSelectUserWorkflows(resource.id);
                            }}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  {expandedRows.includes(resource.id) && (
                    <tr>
                      <td className="m-0 p-0">
                        <Table hover className="mb-0 pb-0">
                          <tbody>
                            {workflows
                              .slice()
                              .sort((a, b) => {
                                if (orderByDate) {
                                  return orderByDate === "asc"
                                    ? new Date(a.startDate) -
                                        new Date(b.startDate)
                                    : new Date(b.startDate) -
                                        new Date(a.startDate);
                                } else if (orderByStatus) {
                                  return orderByStatus === "asc"
                                    ? (
                                        a.timesheetStatus?.internalCode || ""
                                      ).localeCompare(
                                        b.timesheetStatus?.internalCode || ""
                                      )
                                    : (
                                        b.timesheetStatus?.internalCode || ""
                                      ).localeCompare(
                                        a.timesheetStatus?.internalCode || ""
                                      );
                                }
                                return 0;
                              })
                              .map((workflow) => {
                                const { icon, label } = getStatusDetails(
                                  workflow.timesheetStatus?.internalCode
                                );
                                const showWarningIcon = shouldShowWarningIcon(workflow, returnedStatusInternalCodes);
                                return (
                                  <tr
                                    key={workflow.id}
                                    className="text-center align-middle"
                                  >
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip id={`tooltip-${workflow.id}`}>
                                          {t("openTimesheetDetail")}
                                        </Tooltip>
                                      }
                                    >
                                      <td className="light-green-background" 
                                        onClick={() =>
                                          navigate(`/timesheetDetail/${workflow.id}`, {
                                          state: { startDate: workflow.startDate, endDate: workflow.endDate },
                                        })}
                                        style={{ cursor: "pointer" }}
                                      >
                                        {`${formatDate(
                                          workflow.startDate
                                        )} - ${formatDate(
                                          workflow.endDate
                                        )} ${new Date(
                                          workflow.endDate
                                        ).getFullYear()}`}
                                      </td>
                                    </OverlayTrigger>
                                    <td>
                                      <div className="d-flex align-items-center justify-content-center">
                                        <img
                                          src={`${process.env.PUBLIC_URL}/${icon}.svg`}
                                          alt="Status"
                                          style={{
                                            width: "1rem",
                                          }}
                                          className="mr-2"
                                        />
                                        <span className="status-label">
                                          {label}
                                        </span>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="d-flex align-items-center justify-content-center">
                                        {showWarningIcon?.showWarning ? (
                                          <OverlayTrigger
                                            placement="top"
                                            overlay={
                                              <Tooltip id={`tooltip-${workflow.id}`}>
                                                {showWarningIcon.tooltipMessage}
                                              </Tooltip>
                                            }
                                          >
                                            <img
                                              src={`${process.env.PUBLIC_URL}/warning.svg`}
                                              alt="Warning"
                                              style={{
                                                width: "1.5rem",
                                              }}
                                            />
                                          </OverlayTrigger>
                                        ) : null}                                       
                                      </div>
                                    </td>
                                    <td className="text-start">
                                      <Accordion>
                                        <Accordion.Item eventKey="0">
                                          <Accordion.Header>
                                            <strong>{t("totalTime")}</strong>:{" "}
                                            {formatDuration(workflow.totalTime)}
                                          </Accordion.Header>
                                          <Accordion.Body>
                                            <div>
                                              {t("approved")}:{" "}
                                              {formatDuration(
                                                workflow.totalApprovedTime
                                              )}
                                            </div>
                                            <div>
                                              {t("rejected")}:{" "}
                                              {formatDuration(
                                                workflow.totalRejectedTime
                                              )}
                                            </div>
                                            <div>
                                              {t("pending")}:{" "}
                                              {formatDuration(
                                                workflow.totalPendingTime
                                              )}
                                            </div>
                                          </Accordion.Body>
                                        </Accordion.Item>
                                      </Accordion>
                                    </td>
                                    <td className="text-start">
                                      <Accordion>
                                        <Accordion.Item eventKey="0">
                                          <Accordion.Header>
                                            <strong>
                                              {t("totalLeaveTime")}
                                            </strong>
                                            :{" "}
                                            {formatDuration(
                                              workflow.totalLeaveTime
                                            )}
                                          </Accordion.Header>
                                          <Accordion.Body>
                                            <div>
                                              {t("approved")}:{" "}
                                              {formatDuration(
                                                workflow.totalApprovedLeaveTime
                                              )}
                                            </div>
                                            <div>
                                              {t("rejected")}:{" "}
                                              {formatDuration(
                                                workflow.totalRejectedLeaveTime
                                              )}
                                            </div>
                                            <div>
                                              {t("pending")}:{" "}
                                              {formatDuration(
                                                workflow.totalPendingLeaveTime
                                              )}
                                            </div>
                                          </Accordion.Body>
                                        </Accordion.Item>
                                      </Accordion>
                                    </td>
                                    <td className="light-green-background">
                                      <Form.Check
                                        type="checkbox"
                                        id="selectRowCheckbox"
                                        className="custom-switch"
                                        checked={selectedWorkflows.includes(
                                          workflow.id
                                        )}
                                        onClick={(e) => e.stopPropagation()}
                                        onChange={() =>
                                          toggleSelectWorkflow(workflow.id)
                                        }
                                      />
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
}

export default TimesheetApproval;
